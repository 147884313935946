
import { OfferService } from '@/services';
import { OfferModule } from '@/store/modules';
import { OfferDetail } from '@/types/offer.types';
import { Component, Vue } from 'vue-property-decorator';
import { ExploreOfferCards } from '@/components/offers';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component({
  components: {
    ExploreOfferCards
  }
})
export default class ExploreOffers extends Vue {
  currentTenant = tenants[ActiveTenant];

  async mounted() {
    await this.fetchData();
  }

  cards: OfferDetail[] = [];

  async fetchData() {
    try {
      let offers = OfferModule.offers;
      if (!offers || offers.length == 0) {
        const response = await OfferService.fetchOffers();
        offers = response?.items;
      }
      this.cards = offers.slice(0, 3);
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
  }
}
